import { Tax } from '@guiker/payment-shared'
import { Payment } from '@guiker/shared-framework'

import { Instalment, Payer, RentPaymentsPlan } from '../entity'
import { UnprocessableServiceFeeTypeError } from './errors'

type ServiceFeeTaxBreakdown = {
  accountId: string
  type: string
  amount: number
  percentage: number
}[]

export const buildTenantInstalmentPaymentInfo = (args: {
  payer: Payer
  instalment: Instalment
  serviceFee: Payment.RentalServiceFee
  total: RentPaymentsPlan['total']
  applicableTax?: Tax
  ignoreFeeType?: boolean
}) => {
  const { payer, instalment, serviceFee, total, applicableTax, ignoreFeeType = false } = args

  if (serviceFee.type === Payment.RentalServiceFeeType.FIXED_AMOUNT) {
    if (ignoreFeeType) {
      return {
        amount: payer.amount,
        serviceFeeTaxBreakdown: [] as ServiceFeeTaxBreakdown,
      }
    } else {
      throw new UnprocessableServiceFeeTypeError()
    }
  } // FIXED_AMOUNT should never reach here but just in case
  const payableAmount = Math.round((payer.amount * instalment.amount) / total.amount)
  const serviceFeeAmount = payableAmount * (serviceFee.value / 100)

  const serviceFeeTaxBreakdown: ServiceFeeTaxBreakdown =
    !serviceFee.skipTax && applicableTax?.rates?.length
      ? applicableTax.rates.map((rate) => ({ ...rate, amount: rate.percentage * serviceFeeAmount }))
      : []

  const serviceFeeTaxAmount = serviceFeeTaxBreakdown.length
    ? serviceFeeTaxBreakdown.reduce((total, t) => (total += t.amount), 0)
    : 0

  const serviceFeeAmountAfterTax = serviceFeeAmount + serviceFeeTaxAmount
  const transferAmount = Math.round(payableAmount - serviceFeeAmountAfterTax)

  return {
    amount: payableAmount,
    transferAmount,
    serviceFeeTaxBreakdown,
    totalServiceFee: serviceFeeAmountAfterTax,
    serviceFeeAmount,
  }
}
